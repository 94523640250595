import React from "react";
import { ButtonBox } from "./ButtonComponent.styled";
import Typography from "../../v2/typography";

interface Props {
  text: string;
  onClick: () => void;
  primary?: boolean;
  disabled?: boolean;
  wide?: boolean;
  spaced?: boolean;
  loading?: number;
  redOutline?: boolean;
  tooltip?: string;
  inversePrimary?: boolean;
}

/**
 * Component for a button with optional color. A primary button has a colored background.
 * @param interface
 * @returns a button.
 */
export const ButtonComponent = React.forwardRef<HTMLButtonElement | null, Props>(
  (
    {
      text,
      onClick,
      tooltip,
      primary = false,
      disabled = false,
      wide = false,
      spaced = false,
      loading = 0,
      redOutline = false,
      inversePrimary = false,
    },
    ref,
  ) => {
    return (
      <ButtonBox
        ref={ref}
        primary={primary}
        redOutLine={redOutline}
        disabled={disabled || loading > 0}
        wide={wide}
        onClick={onClick}
        loading={loading}
        spaced={spaced}
        title={tooltip}
        inversePrimary={inversePrimary}
      >
        <svg viewBox="0 0 24 24"></svg>
        <Typography variant="DescriptionBold">{text}</Typography>
      </ButtonBox>
    );
  },
);

ButtonComponent.displayName = "ButtonComponent";
